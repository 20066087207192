@font-face {
	font-family: NotoSansKR;
	src: url(/fonts/NotoSansKR/NotoSansKR-Thin.otf) format('otf');
	font-weight: 100;
	font-display: swap;
}

@font-face {
	font-family: NotoSansKR;
	src: url(/fonts/NotoSansKR/NotoSansKR-Light.otf) format('otf');
	font-weight: 300;
	font-display: swap;
}

@font-face {
	font-family: NotoSansKR;
	src: url(/fonts/NotoSansKR/NotoSansKR-Regular.otf) format('otf');
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: NotoSansKR;
	src: url(/fonts/NotoSansKR/NotoSansKR-Medium.otf) format('otf');
	font-weight: 500;
	font-display: swap;
}

@font-face {
	font-family: NotoSansKR;
	src: url(/fonts/NotoSansKR/NotoSansKR-Bold.otf) format('otf');
	font-weight: 700;
	font-display: swap;
}

@font-face {
	font-family: NotoSansKR;
	src: url(/fonts/NotoSansKR/NotoSansKR-Black.otf) format('otf');
	font-weight: 900;
	font-display: swap;
}

@font-face {
	font-family: Pretendard;
	src: url(/fonts/Pretendard/Pretendard-Thin.otf);
	font-weight: 100;
	font-display: swap;
}

@font-face {
	font-family: Pretendard;
	src: url(/fonts/Pretendard/Pretendard-ExtraLight.otf);
	font-weight: 200;
	font-display: swap;
}

@font-face {
	font-family: Pretendard;
	src: url(/fonts/Pretendard/Pretendard-Light.otf);
	font-weight: 300;
	font-display: swap;
}

@font-face {
	font-family: Pretendard;
	src: url(/fonts/Pretendard/Pretendard-Regular.otf);
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: Pretendard;
	src: url(/fonts/Pretendard/Pretendard-Medium.otf);
	font-weight: 500;
	font-display: swap;
}

@font-face {
	font-family: Pretendard;
	src: url(/fonts/Pretendard/Pretendard-SemiBold.otf);
	font-weight: 600;
	font-display: swap;
}

@font-face {
	font-family: Pretendard;
	src: url(/fonts/Pretendard/Pretendard-Bold.otf);
	font-weight: 700;
	font-display: swap;
}

@font-face {
	font-family: Pretendard;
	src: url(/fonts/Pretendard/Pretendard-ExtraBold.otf);
	font-weight: 800;
	font-display: swap;
}

@font-face {
	font-family: Pretendard;
	src: url(/fonts/Pretendard/Pretendard-Black.otf);
	font-weight: 900;
	font-display: swap;
}

html {
	margin: 0;
	padding: 0;
	position: relative;
	/* z-index: -1; */
	font-size: 100px;
}

body {
	margin: 0;
	padding: 0;
	overflow-x: hidden;
	height: auto;
	font-size: 0.16rem;
	font-family: "Encode Sans Expanded", HG, 'Spoqa Han Sans Neo', sans-serif;
	-webkit-overflow-scrolling: touch;
	-webkit-tap-highlight-color: transparent;
	top: 0 !important;
}

body > .skiptranslate {
    display: none;
}

input {
	/* -webkit-appearance: none; */
	border-radius: 0;
	font-family: inherit;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	/* -webkit-appearance: none; */
	margin: 0;
}

html,
body,
body > div:nth-child(2) {
	height: 100%;
}
*,
::after,
::before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	outline: 0;
}
/* ::-moz-selection {
	background: rgba(255, 110, 58, 0.85);
	color: white;
}
::selection {
	background: rgba(255, 110, 58, 0.85);
	color: white;
} */

button,
span,
p {
	margin: 0;
	padding: 0;
	width: fit-content;
	height: fit-content;
}
a:active :hover {
	background-color: transparent;
}

a {
	text-decoration: none;
	color: black;
}
button {
	font-family: inherit;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
	display: block;
}

img {
	object-fit: contain;
}

.app-container {
	width: 100vw;
	height: fit-content;
	min-height: 100vh;
	margin: 0 auto;
	position: relative;

	display: flex;
	flex-direction: column;
}
